<template>
  <v-card>
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a
              v-if="company?.country === 'ch'"
              :href="`tel:${generalPhoneDE}`"
            >
              {{ generalPhoneDE }}
            </a>
            <a
              v-else
              :href="`tel:${generalPhoneCH}`"
            >
              {{ generalPhoneCH }}
            </a>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('labels.phone') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a :href="`mailto:${email}`">
              {{ email }}
            </a>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('labels.email') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    company: {
      type: Object,
      default: null
    }
  },

  data () {
    const defaultContact = this.$features.feature(featureNames.SUPPORT_CONTACT).config
    return {
      generalPhoneCH: defaultContact.phone.ch,
      generalPhoneDE: defaultContact.phone.de,
      email: defaultContact.support.email
    }
  }
}
</script>
