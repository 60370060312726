<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <h2 class="title pb-4">
        <v-chip
          class="ma-2"
          label
          color="warning"
        >
          {{ $t('email-verification.verification-idle.chip') }}
        </v-chip>
        {{ $t('email-verification.verification-idle.title') }}
      </h2>
      <p>
        {{ $t('email-verification.verification-idle.body-texts.0') }}
      </p>
      <h3>{{ $t('email-verification.verification-idle.body-texts.1') }}</h3>
      <template v-if="isDomainVerified">
        <p>{{ $t('email-verification.verification-idle.body-texts.2') }}</p>
        <v-btn
          class="my-2"
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="startActivation"
        >
          {{ $t('buttons.email-verification.verification-idle.activate-funnel') }}
        </v-btn>
      </template>
      <template v-else-if="isSenderVerified">
        <p>{{ $t('email-verification.verification-idle.body-texts.3') }}</p>
        <v-btn
          class="my-2"
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="startActivation"
        >
          {{ $t('buttons.email-verification.verification-idle.activate-funnel') }}
        </v-btn>
      </template>
      <template v-else>
        <p>{{ $t('email-verification.verification-idle.body-texts.4') }}</p>
        <ol>
          <li>{{ $t('email-verification.verification-idle.body-texts.5') }}</li>
          <v-btn
            class="my-2"
            color="primary"
            :loading="loading"
            :disabled="loading"
            small
            @click="startActivation"
          >
            {{ $t('buttons.email-verification.verification-idle.activate-mail') }}
          </v-btn>
          <p>{{ $t('email-verification.verification-idle.body-texts.6', { email: company?.ambassador?.email }) }}</p>
          <li>{{ $t('email-verification.verification-idle.body-texts.7') }}</li>
        </ol>
      </template>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      :md="isDomainVerified || isSenderVerified ? 5 : 6"
    >
      <div class="design-wrapper">
        <Leaf
          class="design-elements"
          width="1000"
          style="top:-300px; right: -750px; opacity: 0.2"
          fill
        />
      </div>

      <v-img
        :src="require('@/../public/img/email-computer.svg')"
        height="450px"
        style="z-index:10"
        contain
      />
    </v-col>
  </v-row>
</template>

<script>
import START_HOPPERMATION_ACTIVATION from './queries/StartHoppermationActivation.gql'
import HOPPERMATION_ORGANIZATION from '@/modules/emailVerification/queries/HoppermationOrganization.gql'
import AMBASSADOR from './queries/Ambassador.gql'
import HOPPERMATION_DEFAULT_SENDER from '@/modules/emailVerification/queries/HoppermationDefaultSender.gql'
import GET_DOMAIN from '@/queries/GetDomain.gql'
import bus, { eventNames } from '@/lib/eventBus'
import Leaf from '@/components/design-elements/leaf.vue'

export default {
  components: { Leaf },
  props: {
    organization: { type: Object, default: () => {} },
    defaultSender: { type: Object, default: () => undefined },
    onDefaultSenderCreated: { type: Function, default: () => ({}) }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    isDomainVerified () {
      return this.domain?.isDKIMVerified && this.domain?.isReturnPathDomainVerified
    },
    isSenderVerified () {
      return this.defaultSender?.isVerified
    }
  },
  apollo: {
    domain: {
      query: GET_DOMAIN,
      variables () {
        return { companyId: this.$auth.user.companyId }
      }
    },
    company: {
      query: AMBASSADOR,
      variables () {
        return { id: this.$auth.user.companyId }
      }
    }
  },
  methods: {
    updateQuery (store, { data: { organization } }) {
      const data = store.readQuery({ query: HOPPERMATION_ORGANIZATION, variables: { companyId: this.$auth.user.companyId } })
      data.organization = organization
      store.writeQuery({ query: HOPPERMATION_ORGANIZATION, data, variables: { companyId: this.$auth.user.companyId } })
    },
    async startActivation () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: START_HOPPERMATION_ACTIVATION,
          variables: { companyId: this.$auth.user.companyId },
          update: this.updateQuery,
          refetchQueries: [{
            query: HOPPERMATION_DEFAULT_SENDER,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        if (this.isDomainVerified || this.isSenderVerified) {
          bus.$emit(eventNames.SHOW_SNACKBAR, {
            color: 'success',
            text: this.$t('alerts.email-verification.verification-idle.success')
          })
        } else {
          this.onDefaultSenderCreated()
          bus.$emit(eventNames.SHOW_SNACKBAR, {
            color: 'success',
            text: this.$t('alerts.email-verification.verification-idle.success-sender')
          })
        }
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.email-verification.verification-idle.error') })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  width:300px;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.flip-horizontal, .x-grid-cell-inner, .x-column-header-text, .x-panel-header-text {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/
}
</style>
