/**
 * This is a simple state machine mixin. Actually it's not a machine,
 * it's just a simple state manager.
 *
 * How to use:
 * 1. Import the mixin in your component
 * 2. Define the initial state in the data property (`initialState`) - default is 'idle'
 * 3. Use the `isState` method to check if the current state is the one you want
 * 4. Use the `setState` method to change the state
 *
 * Example states:
 * - form, form.idle, form.loading, form.success, form.error
 * - open, loading, closed
 *
 * As you can see, you can use the dot notation to create sub states.
 * You can use the `isState` method to check for parent state.
 *
 * Example usage:
 * this.isState('form') // true if the current state is form, form.idle, form.loading, form.success, form.error
 * this.isState('form.loading') // true if the current state is form.loading
 */
export default {
  data () {
    return {
      initialState: 'idle',
      state: ''
    }
  },

  created () {
    this.state = this.initialState
  },

  methods: {
    isState (state) {
      const regx = new RegExp(`^${state}(.[a-z]+|$)`)
      return regx.test(this.state)
    },

    setState (newState) {
      this.state = newState
      this.$emit('state', this.state)
    }
  }
}
