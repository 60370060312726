<template>
  <v-container
    v-if="billingOnboardingDetailsLoaded"
    class="layout-container"
  >
    <Billing
      v-if="showPaymentStep"
      :has-payment-provider="hasPaymentProvider"
      @completed="handleBillingCompleted"
    />
    <onboarding v-else />
  </v-container>
</template>

<script>
import Billing from '@/modules/onboarding/Billing'
import GET_BILLWERK_COMPANY from '@/modules/onboarding/queries/GetBillingOnboardingDetails.gql'
import GET_ONBOARDING_BILLING_DATA from '@/modules/billwerk/subscribe/queries/getOnboardingBillingData.gql'
const Onboarding = () => import(/* webpackChunkName: "onboarding" */ '@/modules/onboarding')

export default {
  inject: ['companyBaseData'],
  components: { Billing, Onboarding },
  data () {
    return {
      billingOnboardingDetailsLoaded: false,
      billingOnboardingDetailsData: {}
    }
  },
  provide () {
    return {
      billingOnboardingDetailsData: this.billingOnboardingDetailsData
    }
  },
  computed: {
    showPaymentStep () {
      const details = this.billingOnboardingDetails
      if (!details || !details.isSignupComplete) return false
      return !details.paymentProviderRole || (details.paymentProviderRole === 'BlackLabel' && !this.hasPaymentInfo)
    },
    hasPaymentProvider () {
      return !!this.billingOnboardingDetails?.paymentProviderRole
    },
    hasPaymentInfo () {
      const validAddress = this.customer && Object.keys(this.customer.address).length > 0 && Object.keys(this.customer.address).every(key => {
        return this.customer.address[key].length > 0
      })
      const validInfo = this.customer && Object.keys(this.customer).length > 0 && Object.keys(this.customer).every(key => {
        if (key !== 'address') return this.customer[key].length > 0
        else return true
      })
      return this.customer && validInfo && validAddress
    }
  },
  methods: {
    async handleBillingCompleted () {
      await this.$apollo.queries.billingOnboardingDetails.refetch()
      this.$router.push('/onboarding/company')
    }
  },
  apollo: {
    customer: {
      query: GET_ONBOARDING_BILLING_DATA,
      update (data) {
        const {
          customer: {
            companyName, firstName, lastName, vatId,
            address: { street, houseNumber, postalCode, city, country }
          }
        } = data.onboardingBillingData
        return {
          emailAddress: this.$auth.user.email,
          companyName: companyName || this.customer?.companyName || '',
          firstName: firstName || this.customer?.firstName || '',
          lastName: lastName || this.customer?.lastName || '',
          vatId: vatId || this.customer?.vatId || '',
          address: {
            street: street || this.customer?.address?.street || '',
            houseNumber: houseNumber || this.customer?.address?.houseNumber || '',
            postalCode: postalCode || this.customer?.address?.postalCode || '',
            city: city || this.customer?.address?.city || '',
            country: country?.toUpperCase() || this.customer?.address?.country || ''
          }
        }
      }
    },

    billingOnboardingDetails: {
      query: GET_BILLWERK_COMPANY,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      result ({ data }) {
        if (data?.billingOnboardingDetails) {
          Object.keys(data.billingOnboardingDetails).forEach(key => {
            this.billingOnboardingDetailsData[key] = data.billingOnboardingDetails[key]
          })
          this.billingOnboardingDetailsLoaded = true
        }
      }
    }
  }
}
</script>
