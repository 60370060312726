<template>
  <div>
    <div>
      <h2 class="pr-4 ml-n2">
        {{ $t('onboarding.contacts-data.contacts.title') }}
      </h2>
    </div>

    <Feature
      v-if="isOnboarding"
      :feature-slug="featureNames.CONTRACT"
    >
      <div>
        <div class="pb-4 mb-2">
          <h4
            id="contact_person_contract_headline"
            class="pr-4 ml-n2"
          >
            {{ $t('onboarding.contacts-data.contacts.contract.title') }}
          </h4>
        </div>
        <v-row class="d-flex justify-space-between">
          <v-col
            cols="12"
            md="6"
            class="pr-0 pa-0 pr-md-2"
          >
            <v-text-field
              id="contact_person_contract_firstname"
              ref="firstname"
              v-model.lazy="contactPersonContract.firstname"
              outlined
              :label="$t('onboarding.contacts-data.contacts.contract.firstname')"
              :placeholder="$t('onboarding.contacts-data.contacts.contract.firstnamePlaceholder')"
              validate-on-blur
              :rules="[rules.optionalForOnboarding(isOnboarding)]"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pa-0"
          >
            <v-text-field
              ref="lastname"
              v-model.lazy="contactPersonContract.lastname"
              outlined
              :label="$t('onboarding.contacts-data.contacts.contract.lastname')"
              :placeholder="$t('onboarding.contacts-data.contacts.contract.lastnamePlaceholder')"
              validate-on-blur
              :rules="[rules.optionalForOnboarding(isOnboarding)]"
            />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between">
          <v-col
            cols="12"
            md="6"
            class="pr-0 pa-0 pr-md-2"
          >
            <PhoneField
              v-model="contactPersonContract.phone"
              :rules="[rules.optionalForOnboarding(isOnboarding)]"
              update-on-input
              validate-phone
              validate-on-blur
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pa-0"
          >
            <v-text-field
              ref="email"
              v-model.lazy="contactPersonContract.email"
              type="email"
              outlined
              :label="$t('onboarding.contacts-data.contacts.contract.email')"
              :placeholder="$t('onboarding.contacts-data.contacts.contract.emailPlaceholder')"
              :rules="[rules.email, rules.optionalForOnboarding(isOnboarding)]"
              validate-on-blur
            />
          </v-col>
        </v-row>
      </div>
    </Feature>

    <div class="pb-4 mb-2">
      <h4
        id="contact_person_system_headline"
        class="pr-4 ml-n2"
      >
        {{ $t('onboarding.contacts-data.contacts.system.title') }}
      </h4>
    </div>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          id="contact_person_system_firstname"
          ref="firstname"
          v-model.lazy="contactPerson.firstname"
          outlined
          :label="$t('onboarding.contacts-data.contacts.system.firstname')"
          :placeholder="$t('onboarding.contacts-data.contacts.system.firstnamePlaceholder')"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="lastname"
          v-model.lazy="contactPerson.lastname"
          outlined
          :label="$t('onboarding.contacts-data.contacts.system.lastname')"
          :placeholder="$t('onboarding.contacts-data.contacts.system.lastnamePlaceholder')"
          validate-on-blur
        />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <PhoneField
          v-model="contactPerson.phone"
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
          update-on-input
          validate-phone
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0 mt-7 mt-md-0"
      >
        <v-text-field
          ref="email"
          v-model.lazy="contactPerson.email"
          type="email"
          outlined
          :label="$t('onboarding.contacts-data.contacts.system.email')"
          :placeholder="$t('onboarding.contacts-data.contacts.system.emailPlaceholder')"
          :rules="[rules.email, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
    </v-row>

    <Feature
      v-slot="{feature}"
      :feature-slug="featureNames.CONTACTS"
    >
      <v-row>
        <v-col
          v-if="feature.config.hasAdAgencyContact"
          xl="6"
          cols="12"
          :class="{'pr-3': $vuetify.breakpoint.lgAndDown, 'pr-5': $vuetify.breakpoint.xlOnly}"
        >
          <contact
            :contact="contactAdAgency"
            has-optional-fields
            :title="$t('onboarding.contacts-data.contacts.adAgency.title')"
            :subtitle="$t('onboarding.contacts-data.contacts.adAgency.subtitle')"
          />
        </v-col>
        <v-col
          v-if="feature.config.hasDesignContact"
          xl="6"
          cols="12"
        >
          <contact
            :contact="contactWebsite"
            has-optional-fields
            :title="$t('onboarding.contacts-data.contacts.design.title')"
            :subtitle="$t('onboarding.contacts-data.contacts.design.subtitle')"
          />
        </v-col>
      </v-row>
    </Feature>
  </div>
</template>

<script>
import { email, optionalForOnboarding, required } from '@/lib/validation'
import Contact from './Contact'
import PhoneField from '@/components/PhoneField.vue'
import featureMixin from '@/mixins/feature'

export default {
  components: { Contact, PhoneField },
  mixins: [featureMixin],
  props: {
    contactPerson: {
      type: Object,
      required: true
    },
    contactPersonContract: {
      type: Object,
      required: true
    },
    contactAdAgency: {
      type: Object,
      required: true
    },
    contactWebsite: {
      type: Object,
      required: true
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.rules = { email, optionalForOnboarding, required }
  }
}
</script>
