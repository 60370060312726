<template>
  <v-container
    :class="{'pa-0':$vuetify.breakpoint.smAndDown}"
    class="d-flex justify-center"
  >
    <v-card max-width="700">
      <v-card-title class="d-flex justify-center">
        Bezahlmethode wählen
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Wählen Sie aus verschiedenen Zahlungsmethoden Ihre bevorzugte aus.
      </v-card-text>
      <Subscribe
        :country-code="$auth.user.locale"
        :email="$auth.user.email"
        :has-payment-provider="hasPaymentProvider"
        is-form-only
        show-coupon
        @completed="completed"
      />
    </v-card>
  </v-container>
</template>

<script>
import Subscribe from '@/modules/billwerk/subscribe'

export default {
  components: { Subscribe },
  props: {
    hasPaymentProvider: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    completed () {
      this.$emit('completed')
    }
  }

}
</script>
