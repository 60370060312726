<template>
  <step-container
    v-if="company"
    :required-data="requiredData"
    :data="{contactPerson, contactPersonContract, contactAdAgency, contactWebsite }"
    :on-submit="submit"
    :has-steps="isOnboarding"
    v-on="$listeners"
  >
    <ContactsData
      v-if="isOnboarding"
      v-bind="{contactPerson, contactPersonContract, contactAdAgency, contactWebsite }"
      is-onboarding
    />

    <v-card
      v-else
      flat
    >
      <ContactsData
        class="mx-4"
        v-bind="{contactPerson, contactPersonContract, contactAdAgency, contactWebsite }"
      />
    </v-card>
  </step-container>
</template>

<script>
import StepContainer from './StepContainer.vue'
import UPDATE_COMPANY_CONTACT_PERSON from './queries/UpdateCompanyContactPerson.gql'
import COMPANY from './queries/Company.gql'
import ContactsData from '@/components/forms/ContactsData'

export default {
  components: { ContactsData, StepContainer },
  props: {
    companyId: {
      type: String,
      required: true
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contactPersons: [],
      contactPerson: {},
      contactPersonContract: {},
      contactAdAgency: {},
      contactWebsite: {},
      ids: {}
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.companyId
        }
      }
    }
  },
  watch: {
    company: {
      deep: true,
      handler (data) {
        this.contactPerson = this.getContactPerson(data.contactPersons, 'GENERAL')
        this.contactPersonContract = this.getContactPerson(data.contactPersons, 'CONTRACT')
        this.contactAdAgency = this.getContactPerson(data.contactPersons, 'AD_AGENCY')
        this.contactWebsite = this.getContactPerson(data.contactPersons, 'WEBSITE')
        this.ids = this.getIds(data.contactPersons)
      }
    }
  },
  methods: {
    getIds (contactPersons = []) {
      const getId = (type) => contactPersons.find((contact) => contact.type === type)?.id
      return {
        GENERAL: getId('GENERAL'),
        CONTRACT: getId('CONTRACT'),
        AD_AGENCY: getId('AD_AGENCY'),
        WEBSITE: getId('WEBSITE')
      }
    },
    requiredData ({ contactPerson: { firstname, lastname, phone, email } }) {
      return { firstname, lastname, phone, email }
    },
    getContactPerson (contactPersons, type) {
      const contact = contactPersons.find((contact) => contact.type === type) || { }
      const {
        firstname = null,
        lastname = null,
        email = null,
        phone = null,
        address: {
          street = null,
          city = null,
          zip = null,
          country = null
        } = {}
      } = contact
      return {
        firstname,
        lastname,
        email,
        phone,
        address: { street, city, zip, country }
      }
    },
    async submit () {
      await Promise.all([
        this.updateContact('GENERAL', this.contactPerson),
        this.updateContact('CONTRACT', this.contactPersonContract),
        this.updateContact('AD_AGENCY', this.contactAdAgency),
        this.updateContact('WEBSITE', this.contactWebsite)
      ])
    },
    async updateContact (type, { companyName, firstname, lastname, email, phone, address }) {
      const addressIsEmpty = address === null || JSON.stringify(address) === JSON.stringify({})
      return this.$apollo.mutate({
        mutation: UPDATE_COMPANY_CONTACT_PERSON,
        variables: {
          input: {
            id: this.ids[type],
            companyId: this.companyId,
            type,
            companyName,
            firstname,
            lastname,
            email,
            phone,
            ...(!addressIsEmpty && {
              address: {
                street: address.street,
                city: address.city,
                zip: address.zip,
                country: address.country
              }
            })
          }
        },
        refetchQueries: [
          { query: COMPANY, variables: { id: this.companyId } }
        ]
      })
    }
  }
}
</script>
