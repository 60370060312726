<template>
  <div>
    <div
      v-if="isSenderVerified || isDomainVerified"
      class="d-flex"
    >
      <h2 class="mx-10 w-full align-self-center">
        {{ $t('email-verification.verification-sent.title') }}
      </h2>
      <v-progress-circular
        indeterminate
        :size="80"
        :width="10"
        color="primary"
        class="mx-10"
      />
    </div>
    <div v-else>
      <div class="d-flex mb-4">
        <v-icon
          v-if="defaultSenderCreated"
          class="mr-4"
          color="info"
          size="60"
        >
          mdi-information
        </v-icon>
        <v-icon
          v-else
          class="mr-4"
          color="error"
          size="60"
        >
          mdi-alert
        </v-icon>
        <div v-if="defaultSenderCreated">
          <h3 class="info--text">
            {{ $t('alerts.email-verification.verification-sent.sender-created.success', { sender: defaultSender?.fromEmail }) }}
          </h3>
          <div>{{ $t('alerts.email-verification.verification-sent.sender-created.success-info') }}</div>
        </div>
        <div v-else>
          <h3 class="error--text">
            {{ $t('alerts.email-verification.verification-sent.sender-created.error') }}
          </h3>
          <div>{{ $t('alerts.email-verification.verification-sent.sender-created.error-info') }}</div>
        </div>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              small
              fab
              :loading="loading"
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
              @click="checkStatus"
            >
              <v-icon :alt="$t('buttons.email-verification.verification-sent.refresh')">
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('buttons.email-verification.verification-sent.refresh') }}</span>
        </v-tooltip>
      </div>
      <h3>{{ $t('email-verification.verification-sent.body-texts.0') }}</h3>
      <ol>
        <li>{{ $t('email-verification.verification-sent.body-texts.1') }} (<strong>{{ defaultSender?.fromEmail }}</strong>).</li>
        <li>{{ $t('email-verification.verification-sent.body-texts.2') }}</li>
        <li>{{ $t('email-verification.verification-sent.body-texts.3') }}</li>
      </ol>
      <p>{{ $t('email-verification.verification-sent.body-texts.4') }}</p>
      <h3>{{ $t('email-verification.verification-sent.body-texts.5') }}</h3>
      <p>
        {{ $t('email-verification.verification-sent.body-texts.6') }}
        <v-btn
          color="primary"
          class="text-none px-0 pb-1 body-1"
          :loading="loading"
          :disabled="loading"
          text
          x-small
          @click="resendVerificationMail"
        >
          {{ $t('buttons.email-verification.verification-sent.resend') }}
        </v-btn><br>
        {{ $t('email-verification.verification-sent.body-texts.7') }}
      </p>
    </div>
  </div>
</template>

<script>
import RESEND_HOPPERMATION_DEFAULT_SENDER_VALIDATION from './queries/ResendHoppermationDefaultSenderValidation.gql'
import GET_DOMAIN from '@/queries/GetDomain.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  props: {
    defaultSenderCreated: { type: Boolean, default: false },
    defaultSender: { type: Object, default: () => null },
    checkStatus: { type: Function, required: true }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    isDomainVerified () {
      return this.domain?.isDKIMVerified && this.domain?.isReturnPathDomainVerified
    },
    isSenderVerified () {
      return this.defaultSender?.isVerified
    }
  },
  apollo: {
    domain: {
      query: GET_DOMAIN,
      variables () {
        return { companyId: this.$auth.user.companyId }
      }
    }
  },
  methods: {
    async resendVerificationMail () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: RESEND_HOPPERMATION_DEFAULT_SENDER_VALIDATION,
          variables: { companyId: this.$auth.user.companyId }
        })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.email-verification.verification-sent.error') })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
