<template>
  <v-card
    class="d-flex flex-column"
    :style="cssProps"
    flat
  >
    <v-card-text
      class="grey lighten-3"
    >
      <div>
        <div>
          <span class="pa-1 font-16">{{ $t('billwerk.cart.trail-hint', [trialDurationDays]) }}</span> {{ $t('billwerk.cart.trail-hint-date', [chargableFromDate]) }}
        </div>
        <v-radio-group v-model="isYearly">
          <v-radio :value="false">
            <template v-slot:label>
              <div :class="{ 'font-weight-bold': !isYearly }">
                {{ $t('billwerk.cart.month', [formatPrice(getPrice('monthly'))]) }}
              </div>
            </template>
          </v-radio>
          <v-radio :value="true">
            <template v-slot:label>
              <div>
                <span :class="{ 'font-weight-bold': isYearly }">
                  {{ $t('billwerk.cart.year', [formatPrice(getPrice('yearly'))]) }}
                </span>

                <v-chip
                  class="ml-2 font-weight-bold"
                  color="primary"
                  pill
                  small
                >
                  {{ $t('billwerk.cart.savings-year', [yearlySavingsFormatted]) }}
                </v-chip>
              </div>
            </template>
          </v-radio>
        </v-radio-group>

        <div
          v-if="isYearly"
          class="mt-4"
        >
          {{ $t('billwerk.cart.trail-period') }}
        </div>
      </div>
    </v-card-text>

    <v-card-text class="primary-light-background">
      <ul class="py-4 condition-list my-n4 font-weight-bold">
        <li class="my-6 d-flex align-start">
          <v-icon
            color="primary"
            class="mr-4 white"
            style="border-radius: 30px"
          >
            mdi-check-circle
          </v-icon>

          <div>
            {{ $t('billwerk.cart.highlights.1', [trialDurationDays]) }}
          </div>
        </li>
        <li class="my-6 d-flex align-start">
          <v-icon
            color="primary"
            class="mr-4 white"
            style="border-radius: 30px"
          >
            mdi-check-circle
          </v-icon>
          <div>
            {{ $t('billwerk.cart.highlights.2') }}
          </div>
        </li>
        <li class="my-6 d-flex align-start">
          <v-icon
            color="primary"
            class="mr-4 white pa-0"
            style="border-radius: 30px"
          >
            mdi-check-circle
          </v-icon>
          <div>
            {{ $t('billwerk.cart.highlights.3', [trialDurationDays]) }}
          </div>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
import featureMixin from '@/mixins/feature'
import { fadeColor } from '@/lib/color'

const TRIAL_DURATION_DAYS = 14

export default {
  mixins: [featureMixin],
  props: {
    countryCode: {
      type: String,
      required: true,
      // Normally our countryCode is lowercase, but in the context of Billwerk it is uppercase.
      validator: (value) => value.toUpperCase() === value
    },
    isYearlyPreselected: {
      type: Boolean,
      default: true
    },
    productName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      trialDurationDays: TRIAL_DURATION_DAYS,
      isYearly: this.isYearlyPreselected
    }
  },

  computed: {
    cssProps () {
      return {
        '--primary-light': fadeColor(this.$vuetify.theme.themes.light.primary, 44)
      }
    },
    billwerkFeature () {
      return this.$features.feature(this.featureNames.BILLWERK)?.config
    },
    priceLists () {
      return this.billwerkFeature.priceLists
    },
    planVariant () {
      return this.billwerkFeature.planVariants[this.productName] || this.billwerkFeature.planVariants
    },
    priceListId () {
      return this.getPriceListId(this.countryCode)
    },
    planVariantId () {
      return this.isYearly ? this.planVariant.yearly.id : this.planVariant.monthly.id
    },

    chargableFromDate () {
      const date = new Date()
      date.setDate(date.getDate() + this.trialDurationDays)
      return date.toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    },

    priceFormatted () {
      const price = this.getPrice()
      return this.formatPrice(price)
    },

    yearlySavings () {
      const yearlyPrice = this.getPrice('yearly')
      const monthlyPrice = this.getPrice('monthly')
      return monthlyPrice * 12 - yearlyPrice
    },

    yearlySavingsFormatted () {
      return this.formatPrice(this.yearlySavings)
    }
  },

  watch: {
    priceListId (newPriceListId) {
      this.$emit('priceListIdChange', newPriceListId)
    },

    planVariantId (newPlanVariantId) {
      this.$emit('planVariantIdChange', newPlanVariantId)
    },
    isYearly () {
      localStorage.setItem('registration.billing.isYearly', this.isYearly)
    }
  },

  mounted () {
    // Initially emit cart to parent component
    this.$emit('priceListIdChange', this.priceListId)
    this.$emit('planVariantIdChange', this.planVariantId)
  },

  methods: {
    getPriceListId (countryCode) {
      return this.priceLists[countryCode]?.id ?? this.priceLists.default.id
    },

    getCurrency () {
      return this.priceLists[this.countryCode]?.currency || this.priceLists.default.currency
    },

    getPrice (billingFrequency = null) {
      if (!billingFrequency) {
        billingFrequency = this.isYearly ? 'yearly' : 'monthly'
      }
      const prices = this.planVariant[billingFrequency].prices
      const price = prices[this.countryCode] || prices.default
      return price
    },

    formatPrice (price) {
      return Intl.NumberFormat(`de-${this.countryCode.toUpperCase()}`, {
        style: 'currency',
        currency: this.getCurrency(),
        minimumFractionDigits: Number.parseInt(price) === price ? 0 : 2
      }).format(price)
    }
  }
}
</script>

<style scoped>
.condition-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.font-16 {
  font-size: 18px;
}

.primary-light-background{
  background-color: var(--primary-light);
}
</style>
