<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-0 pa-0 zero-height"
          v-bind="attrs"
          v-on="on"
        >
          <slot />
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            :color="color"
            dark
            flat
          >
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="dialog=false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-5">
          {{ content }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialog=false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            :color="operation.ctaClass"
            :loading="loadingCta"
            :disabled="disabledCta"
            text
            @click="handleSubmit"
          >
            {{ $t(operation.cta) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

const ACTIONS = {
  ok: {
    submitting: 'labels.dialog.ok.submitting',
    cta: 'labels.dialog.ok.cta',
    ctaClass: 'primary'
  },
  create: {
    submitting: 'labels.dialog.create.submitting',
    cta: 'labels.dialog.create.cta',
    ctaClass: 'primary'
  },
  update: {
    submitting: 'labels.dialog.update.submitting',
    cta: 'labels.dialog.update.cta',
    ctaClass: 'primary'
  },
  delete: {
    submitting: 'labels.dialog.delete.submitting',
    cta: 'labels.dialog.delete.cta',
    ctaClass: 'red'
  }
}

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default: ''
    },
    loadingCta: {
      type: Boolean,
      default: false
    },
    disabledCta: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Function,
      default: () => {}
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    operation () {
      return ACTIONS[this.action]
    }
  },
  methods: {
    handleSubmit () {
      this.submit()
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.zero-height{
  height: 0px !important;
}
</style>
